<template>
    <div>
        <div class="modal fade" id="TSComplainCreateModal" tabindex="-1" aria-labelledby="TSComplainCreateModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl-custom modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="TSComplainCreateModalLabel" class="font-weight-bold">Add Technical Support Complain</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <kendo-grid ref="grid"
                            :data-source="dataSource"
                            :pageable="pageableConfig"
                            :filterable="filterableConfig"
                            :sortable="true"
                            :columns="columns"
                            :resizable="true"
                        >
                        </kendo-grid>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { globalfunc } from '../../../../shared/GlobalFunction';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import technicalSupportServices from '../Script/TechnicalSupportComplainScript.js';
import { kendo_grid } from '../../../../infrastructure/constant/variable';

export default {
    name: 'TechnicalSupportCreateForm',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    // props: ['createDetailClick'],
    mounted:  function () {
        var createDetailClick = this.createDetailClick;
        var grid = this.$refs.grid.kendoWidget();
        var GridElement = grid.element;

        GridElement.on("click", "#CreateDetailButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            createDetailClick(dataItem.ts_visit_id);
        })
    },
    data: function () {
        return {
            dataSource: [],
            columns: [
                { title: "Action", width: 100, headerAttributes:{ style: "text-align:center; vertical-align:middle; font-weight:bold;" }, attributes: { "class": "k-text-center" },
                    template: '<div class="btn-group"> <button type="button" class="btn btn-success btn-md rounded" id="CreateDetailButton"> Create </button> </div>'
                },
                { field: "status", width: 200, title: "Status", headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"} },
                { field: "ts_visit_name", width: 200, title: "Kode TS Visit", headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "ts_visit_date", width: 200, title: "Tgl. TS Visit", headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"},
                    template: "#= kendo.toString(kendo.parseDate(ts_visit_date, 'yyyy-MM-dd'), 'dd-MM-yyyy') #"
                },
                { field: "ts_request_name", width: 200, title: "Kode TS Request", headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "visitor_name", width: 200, title: "Nama Visitor", headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "notes", width: 200, title: "Note", headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "approved_by_sales", width: 200, title: "Approve Sales", headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "approved_by_general_manager", width: 200, title: "Approve GM", headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "approved_by_ts_head", width: 200, title: "Approve Head TS", headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
        }
    },
    methods: {
        addClick(){
            this.dataSource = new kendo.data.DataSource ({
                page: 1,
                pageSize: 10,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort)
                            }
                            const variables = {
                                paging : paging
                            }
                            return { 
                                query: technicalSupportServices.readTSVisitQuery(),
                                variables: variables
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetTSVisit.ts_visit == null){
                            return [];
                        }else{
                            return response.data.GetTSVisit.ts_visit;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetTSVisit.ts_visit == null){
                            return 0;
                        }else{
                            return response.data.GetTSVisit.total;
                        }
                    },
                }
            });
            
            window.$('#TSComplainCreateModal').modal('show');
        },
        createDetailClick(data){
            window.$('#TSComplainCreateModal').modal('hide');
            this.$router.push({ name: 'Technical Support Complain Form', params: {  formtype:'Add', id: data, view:'new' } })
        },
    }
}
</script>
<style scoped>
.modal-xl-custom {
    max-width: 1500px;
}
</style>