<template>
  <div>
        <kendo-grid
            ref="grid" 
            :data-source="TechnicalSupportComplain"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            :resizable="true"
        >
        </kendo-grid>
  </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import {globalfunc} from '../../../../shared/GlobalFunction';
import technicalSupportServices from '../Script/TechnicalSupportComplainScript.js';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import { kendo_grid } from '../../../../infrastructure/constant/variable';

export default {
    name: 'TechnicalSupportComplainGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['deleteClick','editClick'],
    computed: {
        TechnicalSupportComplain () {
            return new kendo.data.DataSource({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort)
                            }
                            return { 
                                query: technicalSupportServices.readTSComplainQuery(),
                                variables : {
                                    paging : paging
                                }
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if (response.data.GetTSComplain.ts_complain == null) {
                            return [];
                        }
                        else {
                            return response.data.GetTSComplain.ts_complain;                            
                        }
                    },
                    total: function (response) {
                        if (response.data.GetTSComplain.ts_complain == null) {
                            return 0;
                        }
                        else {
                            return response.data.GetTSComplain.total;
                        }
                    }
                }
            })
        }
    },
    data: function () {
        return  {
            columns:  [
                { title: "Action", width: 150, attributes: { style: "text-align:center;" }, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template:this.columnButton},
                { field: "status", width: 200, title: "Status", headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"} },
                { field: "ts_complain_name", width: 200, title: "Kode TS Komplain", headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "ts_complain_date", width: 200, title: "Tgl. TS Komplain", headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    template: "#= kendo.toString(kendo.parseDate(ts_complain_date, 'yyyy-MM-dd'), 'dd-MM-yyyy') #", attributes:{style: "text-align:center;"}
                },
                { field: "ts_complain_visit_date", width: 200, title: "Tgl. Kunjungan Komplain", headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    template: "#= kendo.toString(kendo.parseDate(ts_complain_visit_date, 'yyyy-MM-dd'), 'dd-MM-yyyy') #", attributes:{style: "text-align:center;"}
                },
                { field: "ts_visit_name", width: 200, title: "Kode TS Visit", headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "visitor_name", width: 200, title: "Nama Visitor", headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "notes", width: 200, title: "Note", headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
            
        };
    },
    mounted: async function() {
        var editClick = this.$props.editClick;
        var deleteClick = this.$props.deleteClick;
        var grid = this.$refs.grid.kendoWidget();
        var GridElement = grid.element;

        GridElement.on("click", "#EditButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
            editClick(dataItem.ts_complain_id, false);
        })

        GridElement.on("click", "#ViewButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem.ts_complain_id, true);
        })

        GridElement.on("click", "#DeleteButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            deleteClick(dataItem.ts_complain_id);
        })
        
    },
    methods: {
        columnButton(e){
            return this.$globalfunc.gridActionButton("Technical Support Complain")
        },
    },
    
}
</script>