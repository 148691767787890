<template>
  <div>
        <kendo-grid
            ref="grid" 
            :data-source="dataSource"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            :resizable="true"
            v-on:detailinit="detailInit"
        >
        </kendo-grid>
  </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import technicalSupportRequestServices from '../Script/TechnicalSupportRequestScript';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import { kendo_grid } from '../../../../infrastructure/constant/variable';

export default {
    name: 'TechnicalSupportRequestGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['editClick', 'deleteClick', 'approveClick'],
    data: function () {
        return  {
            dataSource:  new kendo.data.DataSource ({
                    page: 1,
                    pageSize: kendo_grid.default_grid_pagesize,
                    serverPaging: false,
                    serverFiltering: false,
                    serverSorting: false,
                    transport: {
                        read: {
                            contentType: "application/json",
                            url: apiBaseUrl,
                            type: "POST",
                            data: function(e) {
                                return { query: technicalSupportRequestServices.readTechnicalSupportRequestQuery() };
                            },
                            beforeSend: function (req) {
                                req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                                req.setRequestHeader('Path', window.location.pathname);
                            }
                        },
                        parameterMap: function(options, operation) {
                            return  kendo.stringify({
                                query: options.query,
                                variables: options.variables
                            });
                        },
                        cache: false,
                    },
                    schema: {
                        data: function (response) {
                            if(response.data.GetTSRequest.ts_request == null){
                                return [];
                            }else{
                                return response.data.GetTSRequest.ts_request;
                            }
                        },
                        total: function (response) {
                            if(response.data.GetTSRequest.ts_request == null){
                                return 0;
                            }else{
                                return response.data.GetTSRequest.total;
                            }
                        },
                    }
                }),
            columns:  [
                { title: "Action", width: 250, attributes: { style: "text-align:center;" }, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template:this.columnButton},
                { field: "status", width: 200, title: "Status", headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"} },
                { field: "ts_request_name", width: 200, title: "Kode TS Request", headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "ts_request_date", width: 200, title: "Tanggal Request", headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    template: "#= kendo.toString(kendo.parseDate(ts_request_date, 'yyyy-MM-dd'), 'dd-MM-yyyy') #", attributes:{style: "text-align:center;"} },
                { field: "project_name", width: 200, title: "Nama Project", headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "sales_name", width: 200, title: "Nama Sales", headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "address", width: 200, title: "Alamat", headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "notes", width: 200, title: "Catatan", headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "approved_by_general_manager", width: 200, title: "Approver GM", headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "approved_by_ts_head", width: 200, title: "Approver Head TS", headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "created_at", width: 200, title: "Tanggal Dibuat", headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    template: "#= kendo.toString(kendo.parseDate(new Date(created_at).toISOString(), 'yyyy-MM-dd'), 'dd-MM-yyyy') #", attributes:{style: "text-align:center;"}}
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
        };
    },
    mounted: async function() {
        var deleteClick = this.$props.deleteClick;
        var editClick = this.$props.editClick;
        var approveClick = this.$props.approveClick;
        var grid = this.$refs.grid.kendoWidget();
        var GridElement = grid.element;
        
        GridElement.on("click", "#EditButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
            
            editClick(dataItem, false);
        })

        GridElement.on("click", "#ViewButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
            
            editClick(dataItem, true);
        })

        GridElement.on("click", "#DeleteButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
           
            deleteClick(dataItem.ts_request_id);
        })

        GridElement.on("click", "#ApproveButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
           
            approveClick(dataItem);
        })
    },
    methods: {
        columnButton(e){
            var disable = "disabled";
            if(e.status == "Waiting for Approvals"){
                disable = "";
            }
            var customButton = `&nbsp;&nbsp;
                                <button type="button" class="btn btn-success btn-sm rounded" id="ApproveButton" `+ disable +`>
                                    <span><i class="fa fa-check"></i> Approve</span>
                                </button>`;
                               
            return this.$globalfunc.gridActionButton("Technical Support Request", customButton)
        },
        detailInit: async function (e) {
            $('<div/>').appendTo(e.detailCell).kendoGrid({
                ref: "TSRequestDetailGrid",
                dataSource: {
                    transport: {
                        read: function (x) {
                            x.success(e.data.ts_request_visitor);
                        },
                    },
                    parameterMap: function(options, operation) {
                        return kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    schema: {
                        data: function (response) {
                            if(response == null)
                                return []
                            else
                                return response
                        },
                        total: function(response) {
                            if(response == null)
                                return 0
                            else
                                return response.length;
                        }
                    },
                    pageSize: kendo_grid.default_detail_grid_pagesize
                },
                scrollable: false,
                sortable: true,
                pageable: true,
                navigatable: true,
                resizable: true,
                columns: [
                    { field: "visitor_id", title: "Kode Visitor", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"} },
                    { field: "visitor_name", title: "Nama Visitor", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"} },
                ],
            })
        }
    }
}
</script>