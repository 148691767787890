import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';

class TechnicalSupportRequestService {
    readTechnicalSupportRequestQuery(){
        var query = `query($paging:ServerPaging){
            GetTSRequest(paging:$paging){
                ts_request {
                    created_at
                    last_update
                    ts_request_id
                    ts_request_date
                    ts_request_name
                    status
                    sales_id
                    sales_name
                    project_id
                    project_name
                    address
                    notes
                    approved_by_general_manager
                    approved_by_ts_head
                    ts_request_visitor {
                        created_at
                        last_update
                        ts_request_id
                        visitor_id
                        visitor_name
                    }
                }
                total
            }
        }`;
        return query;
    }

    async getProjectDropdown(){
        var query = gql`query{
            GetTransactionProject(Status:"Ready"){
                project{
                    project_id
                    project_name
                    sales_id
                    sales_name
                }
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        return globalfunc.objectToArrayConverter(result.data.GetTransactionProject.project, 'TechnicalSupport-TechnicalSupportRequest-Project');
    }

    async getVisitorDropdown(){
        var query = gql`query{
            GetContact{
                contact_id
                contact_name
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        return globalfunc.objectToArrayConverter(result.data.GetContact, 'TechnicalSupport-TechnicalSupportRequest-Visitor');
    }

    async addQuery(variables){
        var query = gql`mutation($data:NewTSRequest!){
            CreateTSRequest(NewTSRequest:$data)
        }`;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async editQuery(variables){
        var query = gql`mutation($id:Int!, $data:NewTSRequest!){
            UpdateTSRequest(TSRequestID:$id, NewTSRequest:$data)
        }`;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async deleteQuery(variables){
        var query = gql`
            mutation ($id: Int!) {
                DeleteTSRequest(TSRequestID: $id)
            }
        `;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async approveQuery(variables){
        var query = gql`
            mutation ($id: Int!) {
                ApproveTSRequest(TSRequestID: $id)
            }
        `;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
}

export default new TechnicalSupportRequestService();