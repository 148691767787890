<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Technical Support Visit</h2>
            </CCardHeader>
            <CCardBody>
                <div class="col-xs-6 text-light pb-2">
                    <button id="add" class="btn btn-success"                   
                    @click="addClick()">
                        <i class="fa fa-plus"></i> Add
                    </button>
                </div>

                <technical-support-visit-grid :key="gridReload" :editClick="editClick" :deleteClick="deleteClick" :approveClick="approveClick"/>
                <technical-support-visit-create-form ref="technicalSupportVisitCreateForm" :reload="reload"/>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import technicalSupportVisitGrid from '../TechnicalSupportVisit/Grid/TechnicalSupportVisitGrid.vue';
import technicalSupportCreateForm from '../TechnicalSupportVisit/Component/TechnicalSupportVisitCreateForm.vue';
import technicalSupportServices from '../TechnicalSupportVisit/Script/TechnicalSupportVisitScript.js';
import { response } from '../../../infrastructure/constant/response';
import { sweet_alert } from '../../../infrastructure/constant/variable';

export default {
    name: 'TechnicalSupportVisit',
    components: {
        'technical-support-visit-grid': technicalSupportVisitGrid,
        'technical-support-visit-create-form': technicalSupportCreateForm,
        // 'technical-support-visit-form': technicalSupportVisitForm,
    },
    mounted () {
        var permission = this.$globalfunc.permissionModule('Technical Support Visit');
        document.getElementById("add").disabled = permission.cperms ? false : true;
    },
    data(){
        return{
            gridReload: 0,
        }
    },
    methods: {
        addClick(){
            this.$refs.technicalSupportVisitCreateForm.addClick();
        },
        editClick(id, view){
            // this.$router.push({ name: 'Technical Support Visit Form', params: { formtype:'Edit', id:id, view:view } });
            const routeData = this.$router.resolve({ name: 'Technical Support Visit Form', params: { formtype:'Edit', id:id, view:view } });
            window.open(routeData.href, '_blank');
        },
        approveClick(data){
            var approveMessage = "Apakah anda yakin ingin menyetujui technical support visit dari \""+ data.ts_visit_name +"\" ?";
            
            this.$swal({
                title: "Confirmation",
                text: approveMessage,
                icon: "info",
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                dangerMode: true
            }).then((result) => {
                var variables = {
                    id : data.ts_visit_id
                }
                if (result.isConfirmed == true) {
                    technicalSupportServices.approveQuery(variables).then(res =>{
                        this.reload();
                        this.$swal("Approved", response.successApprove, "success");
                    })
                    .catch(e => {
                        var errorMessage = this.$globalfunc.ErrorExtractor(e)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            })
        },
        deleteClick(id){
            this.$swal(sweet_alert.delete).then((result) => {
                var variables = {
                    id : id
                }
                if (result.isConfirmed == true) {
                    technicalSupportServices.deleteQuery(variables).then(res =>{
                        this.reload();
                        this.$swal("Info", response.successDelete, "success");
                    })
                    .catch(e => {
                        var errorMessage = this.$globalfunc.ErrorExtractor(e)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            })
        },
        reload(){
            this.gridReload++;
        },
    }
}
</script>

<style scoped>
</style>