import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';
import moment from 'moment';

class TechnicalSupportComplainService {
    readTSComplainQuery() {
        var query = `
            query ($paging:ServerPaging) {
                GetTSComplain (paging:$paging) {
                    ts_complain {
                        created_at
                        last_update
                        ts_complain_id
                        ts_complain_date
                        ts_complain_name
                        ts_complain_visit_date
                        status
                        ts_visit_id
                        ts_visit_name
                        visitor_id
                        visitor_name
                        notes
                    }
                    total
                }
            }
        `;
        return query;
    }
    
    async getTSComplainData(id) {
        const variables = {
            id:id
        }
        var query = gql`
            query ($id:Int!) {
                GetTSComplain (TSComplainID:$id) {
                    ts_complain {
                        created_at
                        last_update
                        ts_complain_id
                        ts_complain_date
                        ts_complain_name
                        ts_complain_visit_date
                        status
                        ts_visit_id
                        ts_visit_name
                        visitor_id
                        visitor_name
                        notes
                    }
                    total
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        return result.data.GetTSComplain.ts_complain[0];
    }
    
    readTSVisitQuery() {
        var query = `
            query ($paging:ServerPaging) {
                GetTSVisit (paging:$paging, status:"Finish") {
                    ts_visit {
                        created_at
                        last_update
                        ts_visit_id
                        ts_visit_date
                        ts_visit_name
                        ts_request_id
                        ts_request_name
                        status
                        visitor_id
                        visitor_name
                        notes
                        attachment
                        approved_by_sales
                        approved_by_general_manager
                        approved_by_ts_head
                    }
                    total
                }
            }
        `;
        return query;
    }
    
    async getTSVisitData(id) {
        const variables = {
            id:id
        }
        var query = gql`
            query ($id:Int!) {
                GetTSVisit (TSVisitID:$id) {
                    ts_visit {
                        created_at
                        last_update
                        ts_visit_id
                        ts_visit_date
                        ts_visit_name
                        ts_request_id
                        ts_request_name
                        status
                        visitor_id
                        visitor_name
                        notes
                        attachment
                        approved_by_sales
                        approved_by_general_manager
                        approved_by_ts_head
                    }
                    total
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        return result.data.GetTSVisit.ts_visit[0];
    }

    async addQuery(variables) {
        var query = gql`
            mutation ($data:NewTSComplain!) {
                CreateTSComplain (NewTSComplain:$data)
            }
        `;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
    
    async editQuery(variables) {
        var query = gql`
            mutation ($id:Int!, $data:NewTSComplain!) {
                UpdateTSComplain(TSComplainID:$id, NewTSComplain:$data)
            }
        `;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async deleteQuery(variables) {
        var query = gql`
            mutation ($id:Int!) {
                DeleteTSComplain(TSComplainID:$id)
            }
        `;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
}

export default new TechnicalSupportComplainService();