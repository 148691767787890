<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Technical Support Complain</h2>
            </CCardHeader>
            <CCardBody>
                <div class="col-xs-6 text-light pb-2">
                    <button id="add" class="btn btn-success"                   
                    @click="addClick()">
                        <i class="fa fa-plus"></i> Add
                    </button>
                </div>

                <technical-support-complain-grid :key="gridReload" :editClick="editClick" :deleteClick="deleteClick" />
                <technical-support-complain-create-form ref="technicalSupportComplainCreateForm" :reload="reload"/>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import technicalSupportComplainGrid from '../TechnicalSupportComplain/Grid/TechnicalSupportCompalinGrid.vue';
import technicalSupportCreateForm from '../TechnicalSupportComplain/Component/TechnicalSupportComplainCreateForm.vue';
import technicalSupportServices from '../TechnicalSupportComplain/Script/TechnicalSupportComplainScript.js';
import { response } from '../../../infrastructure/constant/response';
import { sweet_alert } from '../../../infrastructure/constant/variable';

export default {
    name: 'TechnicalSupportComplain',
    components: {
        'technical-support-complain-grid': technicalSupportComplainGrid,
        'technical-support-complain-create-form': technicalSupportCreateForm,
        // 'technical-support-complain-form': technicalSupportVisitForm,
    },
    mounted () {
        var permission = this.$globalfunc.permissionModule('Technical Support Complain');
        document.getElementById("add").disabled = permission.cperms ? false : true;
    },
    data(){
        return{
            gridReload: 0,
        }
    },
    methods: {
        addClick(){
            this.$refs.technicalSupportComplainCreateForm.addClick();
        },
        editClick(id, view){
            // this.$router.push({ name: 'Technical Support Complain Form', params: { formtype:'Edit', id:id, view:view } });
            const routeData = this.$router.resolve({ name: 'Technical Support Complain Form', params: { formtype:'Edit', id:id, view:view } });
            window.open(routeData.href, '_blank');
        },
        deleteClick(id){
            this.$swal(sweet_alert.delete).then((result) => {
                var variables = {
                    id : id
                }
                if (result.isConfirmed == true) {
                    technicalSupportServices.deleteQuery(variables).then(res =>{
                        this.reload();
                        this.$swal("Info", response.successDelete, "success");
                    })
                    .catch(e => {
                        var errorMessage = this.$globalfunc.ErrorExtractor(e)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            })
        },
        reload(){
            this.gridReload++;
        },
    }
}
</script>

<style scoped>
</style>