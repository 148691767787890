<template>
  <div>
        <kendo-grid
            ref="grid" 
            :data-source="TechnicalSupportVisit"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            :resizable="true"
        >
        </kendo-grid>
        
        <file-viewer ref="fileViewer"/>
  </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import {globalfunc} from '../../../../shared/GlobalFunction';
import technicalSupportServices from '../Script/TechnicalSupportVisitScript';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection';
import FileViewer from '../../../../shared/FileViewer.vue';
import { kendo_grid } from '../../../../infrastructure/constant/variable';

export default {
    name: 'TechnicalSupportVisitGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'file-viewer': FileViewer,
    },
    props: ['deleteClick','editClick', 'approveClick'],
    computed: {
        TechnicalSupportVisit () {
            return new kendo.data.DataSource({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort)
                            }
                            return { 
                                query: technicalSupportServices.readTSVisitQuery(),
                                variables : {
                                    paging : paging
                                }
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetTSVisit.ts_visit == null){
                            return [];
                        }else{
                            return response.data.GetTSVisit.ts_visit;
                            
                        }
                    },
                    total: function (response) {
                        if(response.data.GetTSVisit.ts_visit == null){
                            return 0;
                        }else{
                            return response.data.GetTSVisit.total;
                        }
                    }
                }
            })
        }
    },
    data: function () {
        return  {
            columns:  [
                { title: "Action", width: 250, attributes: { style: "text-align:center;" }, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template:this.columnButton},
                { field: "status", width: 200, title: "Status", headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"} },
                { field: "ts_visit_name", width: 200, title: "Kode TS Visit", headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "ts_visit_date", width: 200, title: "Tgl. TS Visit", headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    template: "#= kendo.toString(kendo.parseDate(ts_visit_date, 'yyyy-MM-dd'), 'dd-MM-yyyy') #", attributes:{style: "text-align:center;"}
                },
                { field: "ts_request_name", width: 200, title: "Kode TS Request", headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "visitor_name", width: 200, title: "Nama Visitor", headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "notes", width: 200, title: "Note", headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "attachment", width: 200, title: "Attachment", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    template:this.columnFile },
                { field: "approved_by_sales", width: 200, title: "Approver Sales", headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "approved_by_general_manager", width: 200, title: "Approver GM", headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "approved_by_ts_head", width: 200, title: "Approver Head TS", headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
            
        };
    },
    mounted: async function() {
        var vueComponent = this;
        var editClick = this.$props.editClick;
        var deleteClick = this.$props.deleteClick;
        var approveClick = this.$props.approveClick;
        var grid = this.$refs.grid.kendoWidget();
        var GridElement = grid.element;

        GridElement.on("click", "#EditButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
            editClick(dataItem.ts_visit_id, false);
        })

        GridElement.on("click", "#ViewButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem.ts_visit_id, true);
        })

        GridElement.on("click", "#DeleteButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            deleteClick(dataItem.ts_visit_id);
        })
        
        GridElement.on("click", "#ApproveButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
           
            approveClick(dataItem);
        })
        
        //File Form
        GridElement.on("click", "#FileButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            vueComponent.$refs.fileViewer.viewClick(dataItem.attachment);
        })
    },
    methods: {
        columnFile(e){
            return this.$globalfunc.gridFileViewButton(e.attachment);
        },
        columnButton(e){
            var disable = "disabled";
            if(e.status == "Waiting for Reviews"){
                disable = "";
            }
            var customButton = `&nbsp;&nbsp;
                                <button type="button" class="btn btn-success btn-sm rounded" id="ApproveButton" `+ disable +`>
                                    <span><i class="fa fa-check"></i> Approve</span>
                                </button>`;
            return this.$globalfunc.gridActionButton("Technical Support Visit", customButton)
        },
    },
    
}
</script>