<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Technical Support Request</h2>
            </CCardHeader>
            <CCardBody>
                <div class="col-xs-6 text-light pb-2">
                    <button id="add" class="btn btn-success"                   
                    @click="addClick()">
                        <i class="fa fa-plus"></i> Add
                    </button>
                </div>

                <technical-support-request-grid :key="gridReload" :editClick="editClick" :deleteClick="deleteClick" :approveClick="approveClick"/>
                <technical-support-request-form ref="technicalSupportRequestForm" :reload="reload"/> 
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import technicalSupportRequestGrid from '../TechnicalSupportRequest/Grid/TechnicalSupportRequestGrid.vue';
import technicalSupportRequestForm from '../TechnicalSupportRequest/Component/TechnicalSupportRequestForm.vue';
import technicalSupportRequestServices from './Script/TechnicalSupportRequestScript';
import { response } from '../../../infrastructure/constant/response';
import { sweet_alert } from '../../../infrastructure/constant/variable';

export default {
    name: 'TechnicalSupportRequest',
    components: {
        'technical-support-request-grid': technicalSupportRequestGrid,
        'technical-support-request-form': technicalSupportRequestForm
    },
    mounted () {
        var permission = this.$globalfunc.permissionModule('Technical Support Request');
        document.getElementById("add").disabled = permission.cperms ? false : true;
    },
    data(){
        return{
            gridReload: 0,
        }
    },
    methods: {
        addClick(){
            this.$refs.technicalSupportRequestForm.addClick();
        },
        editClick(data, view){
            this.$refs.technicalSupportRequestForm.editClick(data, view);
        },
        approveClick(data){
            var approveMessage = "Apakah anda yakin ingin menyetujui technical support request \""+ data.ts_request_name +"\" ?";
            
            this.$swal({
                title: "Confirmation",
                text: approveMessage,
                icon: "info",
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                dangerMode: true
            }).then((result) => {
                var variables = {
                    id : data.ts_request_id
                }
                if (result.isConfirmed == true) {
                    technicalSupportRequestServices.approveQuery(variables).then(res =>{
                        this.reload();
                        this.$swal("Approved", response.successApprove, "success");
                    })
                    .catch(e => {
                        var errorMessage = this.$globalfunc.ErrorExtractor(e)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            })
        },
        deleteClick(id){
            this.$swal(sweet_alert.delete).then((result) => {
                var variables = {
                    id : id
                }
                if (result.isConfirmed == true) {
                    technicalSupportRequestServices.deleteQuery(variables).then(res =>{
                        this.reload();
                        this.$swal("Info", response.successDelete, "success");
                    })
                    .catch(e => {
                        var errorMessage = this.$globalfunc.ErrorExtractor(e)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            })
        },
        reload(){
            this.gridReload++;
        },
    }
}
</script>

<style scoped>
</style>