import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';
import moment from 'moment';

class TechnicalSupportVisitService {
    readTSVisitQuery() {
        var query = `
            query ($paging:ServerPaging) {
                GetTSVisit (paging:$paging) {
                    ts_visit {
                        created_at
                        last_update
                        ts_visit_id
                        ts_visit_date
                        ts_visit_name
                        ts_request_id
                        ts_request_name
                        status
                        visitor_id
                        visitor_name
                        notes
                        attachment
                        approved_by_sales
                        approved_by_general_manager
                        approved_by_ts_head
                    }
                    total
                }
            }
        `;
        return query;
    }
    
    readTSRequestQuery() {
        var query = `
            query ($paging:ServerPaging) {
                GetTSRequest (paging:$paging, status:"Ongoing") {
                    ts_request {
                        created_at
                        last_update
                        ts_request_id
                        ts_request_date
                        ts_request_name
                        status
                        sales_id
                        sales_name
                        project_id
                        project_name
                        address
                        notes
                        approved_by_general_manager
                        approved_by_ts_head
                        ts_request_visitor {
                            created_at
                            last_update
                            ts_request_id
                            visitor_id
                            visitor_name
                        }
                    }
                    total
                } 
            }
        `;
        return query;
    }
    
    async getVisitorDropdown(id) {
        const variables = {
            id:id
        }
        var query = gql`
            query ($id:Int) {
                GetTSRequest (TSRequestID:$id) {
                    ts_request {
                        ts_request_visitor {
                            ts_request_id
                            visitor_id
                            visitor_name
                        }
                    }
                } 
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        return globalfunc.objectToArrayConverter(result.data.GetTSRequest.ts_request[0].ts_request_visitor, 'TechnicalSupport-TechnicalSupportVisit');
    }
    
    async getTSVisitData(id) {
        const variables = {
            id:id
        }
        var query = gql`
            query ($id:Int!) {
                GetTSVisit (TSVisitID:$id) {
                    ts_visit {
                        created_at
                        last_update
                        ts_visit_id
                        ts_visit_date
                        ts_visit_name
                        ts_request_id
                        ts_request_name
                        status
                        visitor_id
                        visitor_name
                        notes
                        attachment
                        approved_by_sales
                        approved_by_general_manager
                        approved_by_ts_head
                    }
                    total
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        return result.data.GetTSVisit.ts_visit[0];
    }

    async addQuery(variables) {
        var query = gql`
            mutation ($data:NewTSVisit!) {
                CreateTSVisit (NewTSVisit:$data)
            }
        `;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
    
    async editQuery(variables) {
        var query = gql`
            mutation ($id:Int!, $data:NewTSVisit!) {
                UpdateTSVisit(TSVisitID:$id, NewTSVisit:$data)
            }
        `;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async deleteQuery(variables) {
        var query = gql`
            mutation ($id:Int!) {
                DeleteTSVisit(TSVisitID:$id)
            }
        `;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async approveQuery(variables){
        var query = gql`
            mutation ($id: Int!) {
                ApproveTSVisit(TSVisitID: $id)
            }
        `;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
}

export default new TechnicalSupportVisitService();